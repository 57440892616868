import React from 'react';
import '../../node_modules/react-slideshow-image/dist/styles.css';
import '../scss/index.scss';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Home from './home';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Home></Home>
  </Layout>
)

export default IndexPage;
